const loadCookiePolicy = (window) => {
  const windowObject = window;
  // eslint-disable-next-line no-underscore-dangle
  windowObject.__CWT__ = typeof __CWT__ === 'object' ? __CWT__ : {};

  // eslint-disable-next-line no-underscore-dangle
  if (!windowObject.__CWT__.cookiePolicyInstance) {
    const body = document.getElementsByTagName('body')[0];
    const script = document.createElement('script');
    script.src = '/assets/cookie-policy/cookie-policy-widget.js';
    script.async = true;
    script.onload = () => {
      // eslint-disable-next-line no-underscore-dangle
      __CWT__.cookiePolicyInstance = window.__CWT__.CookiePolicy();

      if (__CWT__.cookiePolicyInstance.dom) {
        __CWT__.cookiePolicyInstance.dom.message.setAttribute('data-nosnippet', true);
        __CWT__.cookiePolicyInstance.dom.message.innerHTML =
          '<span data-title="We use cookies to make your journey with us better. You accept our" class="hidden-info"></span> <a href="/cookie-policy.html" rel="nofollow" data-title="Cookie Policy" class="hidden-info"></a> <span data-title="while browsing our website." class="hidden-info"></span>';
        __CWT__.cookiePolicyInstance.dom.agreeButton.innerHTML =
          '<span data-title="Close" class="hidden-info"></span>';
        __CWT__.cookiePolicyInstance.dom.controls.removeChild(
          __CWT__.cookiePolicyInstance.dom.detailsLink,
        );
      }
    };
    body.appendChild(script);
  }
};

loadCookiePolicy(window);
